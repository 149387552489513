<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useTaoModals();
useQuestsSocket();

const handleClickOnGift = () =>
	loginGuard({
		success: () => {
			open("LazyOModalChallengeSeason");

			dispatchGAEvent({
				event: "click_button",
				button_name: "winter_challenge_popup",
				location: "gift_right"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "gift_right" });
		}
	});
</script>
<template>
	<div class="challenge-gift" @click="handleClickOnGift">
		<NuxtImg
			class="snow-ball"
			src="/nuxt-img/challenge/snow-ball-gift@2x.png"
			format="webp"
			width="70"
			height="78"
			alt="snow-ball"
		/>
		<AText as="div" :modifiers="['bold', 'uppercase']">{{ t("winter") }}</AText>
		<AText as="div" variant="tallinn" :modifiers="['uppercase']">{{ t("Challenge") }}</AText>
	</div>
</template>
<style lang="scss" scoped>
.challenge-gift {
	width: 90px;
	height: 60px;
	color: var(--coro);
	background: var(--gajuwaka);
	filter: drop-shadow(0px 4.502px 4.502px var(--chile));
	border-top: 2px solid var(--cheremushki);
	border-bottom: 2px solid var(--cheremushki);
	border-left: 2px solid var(--cheremushki);
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: fixed;
	right: 0;
	bottom: 90px;
	z-index: 6;
	cursor: pointer;

	.snow-ball {
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
	}
}
</style>
